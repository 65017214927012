@font-face {
  font-family: "HK-Grotesk-Light";
  src: local("HK-Grotesk-Light"),
    url("./hk-grotesk/WEB/HKGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: "HK-Grotesk-Regular";
  src: local("HK-Grotesk-Regular"),
    url("./hk-grotesk/WEB/HKGrotesk-Light.woff") format("woff");
}

@font-face {
  font-family: "HK-Grotesk-Bold";
  src: local("HK-Grotesk-Bold"),
    url("./hk-grotesk/WEB/HKGrotesk-Bold.woff") format("woff");
}

@font-face {
  font-family: "HK-Grotesk-Black";
  src: local("HK-Grotesk-Black"),
    url("./hk-grotesk/WEB/HKGrotesk-Black.woff") format("woff");
}

@font-face {
  font-family: "HK-Grotesk-BoldLegacy";
  src: local("HK-Grotesk-BoldLegacy"),
    url("./hk-grotesk/WEB/HKGrotesk-BoldLegacy.woff") format("woff");
}

@font-face {
  font-family: "HK-Grotesk-SemiBold";
  src: local("HK-Grotesk-SemiBold"),
    url("./hk-grotesk/WEB/HKGrotesk-SemiBold.woff") format("woff");
}

.MuiSelect-select:focus {
  border: 1px solid yellow;
}
table {
  border-collapse: collapse;
  width: 100%;
}

table,
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-right: none;
  border-left: none;
}

th,
td {
  padding: 8px;
  text-align: left;
}

tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.MuiDataGrid-row.Mui-odd {
  background-color: white;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #f4f7fb;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}